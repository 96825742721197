import React, { FC, useState } from 'react';
import styled from 'styled-components';
import LogoWithName from './LogoWithName';
import HeaderNav from './HeaderNav';
import BurgerMenuButton from './BurgerMenuButton';
import { tablet, useQuery } from 'styles/breakpoints';
import BurgerMenu from './BurgerMenu';
import { useScrollToTop } from 'utils/hooks';
import { useRouter } from 'apis/history';

interface HeaderProps {
  isMobileApp: boolean;
  bgHex?: string;
  isNotSticky?: boolean;
}

const Header: FC<HeaderProps> = ({ isMobileApp, bgHex, isNotSticky }) => {
  const { isTablet } = useQuery();
  const { goToLanding } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsOpen(prevState => !prevState);
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  useScrollToTop(0);

  return (
    <>
      <BurgerMenu isOpen={isOpen} toggleBurgerMenu={toggleBurgerMenu} />
      <Container bgHex={bgHex} isNotSticky={isNotSticky}>
        <HeaderWrapper alignCenter={isMobileApp}>
          {!isMobileApp ? (
            <>
              <LogoContainer
                onClick={() => {
                  document.body.style.overflow = 'auto';
                  goToLanding();
                }}
              >
                <HeaderLogo />
              </LogoContainer>
              <NavContainer>
                <BurgerMenuButton
                  isOpen={isOpen}
                  toggleBurger={toggleBurgerMenu}
                />
              </NavContainer>
            </>
          ) : (
            <LogoContainer>
              <HeaderLogo />
            </LogoContainer>
          )}
        </HeaderWrapper>
      </Container>
    </>
  );
};

export default Header;

const Container = styled.div<{ bgHex?: string; isNotSticky?: boolean }>`
  background: ${({ bgHex }) => (bgHex ? bgHex : '#fff')};
  border-bottom: ${({ bgHex }) => (bgHex ? 'none' : '1px solid #eeeef0')};
  padding: 1.125rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ isNotSticky }) => (isNotSticky ? 'relative' : 'sticky')};
  width: 100%;
  z-index: 99;
  height: 4.5rem;
  top: 0;
  @media ${tablet} {
    height: 4rem;
    padding: 1.125rem 1rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media ${tablet} {
    justify-content: center;
  }
  width: 5.75rem;
  height: 2.25rem;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const HeaderLogo = styled(LogoWithName)`
  width: 5.75rem;
  height: 2.25rem;
  svg {
    width: 5.75rem;
    height: 2.25rem;
  }
`;

const HeaderWrapper = styled.div<{ alignCenter?: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? 'center' : 'space-between'};
  align-items: center;
  width: 100%;
  max-width: 80rem;
`;
