import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

interface HeadlineProps {
  title: string;
  subtitle: string;
}

const Container = styled.div`
  padding: 4rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: #f9f6f4;
  @media ${tablet} {
    padding: 2.25rem 1rem 0;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.032rem;
`;

const Subtitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 48rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.016rem;
  }
`;

const Headline: FC<HeadlineProps> = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

export default React.memo(Headline);
