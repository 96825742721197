import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouter } from 'apis/history';
import { tablet } from 'styles/breakpoints';
import BurgerMenuButton from './BurgerMenuButton';
import { useQuizData } from 'utils/hooks';

interface BurgerMenuProps {
  isOpen: boolean;
  toggleBurgerMenu: () => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, toggleBurgerMenu }) => {
  const { goToLanding, goToContact, goToReviews, goToTerms, goToPrivacy } =
    useRouter();
  const data = useQuizData('pagesLinks');
  const handleNavigation = (page: string) => {
    switch (page) {
      case 'reviews':
        goToReviews();
        break;
      case 'contact':
        goToContact();
        break;
      case 'privacy':
        goToPrivacy();
        break;
      case 'terms':
        goToTerms();
        break;
    }

    toggleBurgerMenu();
  };

  return (
    <>
      <MainBurger isOpen={isOpen}>
        <BurgerContainer isOpen={isOpen}>
          <XButton>
            <BurgerMenuButton isOpen={isOpen} toggleBurger={toggleBurgerMenu} />
          </XButton>

          <nav>
            <StyledList>
              {/* <LinkWrapper onClick={() => handleNavigation('reviews')}>
                {data?.reviews || 'Reviews'}
              </LinkWrapper> */}
              <LinkWrapper>
                <LinkStyled href="https://help.effecto.app/hc/en-us">
                  {data?.helpCenter || 'Help Center'}
                </LinkStyled>
              </LinkWrapper>
              <LinkWrapper onClick={() => handleNavigation('contact')}>
                {data?.contact || 'Contact Us'}
              </LinkWrapper>
              <LinkWrapper onClick={() => handleNavigation('terms')}>
                {data?.terms || 'Terms & Conditions'}
              </LinkWrapper>
              <LinkWrapper onClick={() => handleNavigation('privacy')}>
                {data?.privacy || 'Privacy Policy'}
              </LinkWrapper>
            </StyledList>
          </nav>
        </BurgerContainer>
      </MainBurger>
    </>
  );
};

export default BurgerMenu;

const MainBurger = styled.div<{ isOpen: boolean }>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  z-index: 100;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
  backdrop-filter: blur(4px);
`;

const BurgerContainer = styled.div<{ isOpen: boolean }>`
  padding: 0 2.5rem 1.125rem;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 20rem;
  height: 100vh;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  @media ${tablet} {
    padding: 0 1rem 1.125rem;
  }
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.75rem;
`;

const LinkWrapper = styled.li`
  cursor: pointer;
  list-style-type: none;
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;

  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

const LinkStyled = styled.a`
  color: #000 !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

const XButton = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;
