import React, { ComponentPropsWithoutRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import Arrow from 'assets/icons/quiz/arrow.svg';
import Single from 'assets/icons/quiz-arrow.svg';
import { DynamicImage } from './images/DynamicImage';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  isMultiple?: boolean;
  smallEmoji?: boolean;
  emoji?: string;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  animate: boolean;
  hasImage?: boolean;
}>`
  background: ${({ active }) => (active ? '#E9F6F2' : '#F9F6F4')};
  border: 1px solid
    ${({ active }) => (active ? '#2FBE8F' : 'rgba(28, 28, 40, 0.08)')};
  display: flex;
  flex-direction: ${({ hasImage }) => (hasImage ? 'column' : 'row')};
  justify-content: ${({ hasImage }) => (hasImage ? 'center' : 'space-between')};
  align-items: center;
  min-height: ${({ hasImage }) => (hasImage ? 'unset' : '3.5rem')};
  height: ${({ hasImage }) => (hasImage ? '100%' : '3.5rem')};

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 0.625rem;
  padding: 1rem;
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
`;

const ButtonText = styled(Text)<{ hasImage?: boolean }>`
  color: #000;
  font-size: ${({ hasImage }) => (hasImage ? '0.875rem' : ' 1rem')};
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: ${({ hasImage }) => (hasImage ? '-0.014rem' : '-0.016rem')};
  text-align: ${({ hasImage }) => (hasImage ? 'center' : 'left')};
  ${({ hasImage }) => !hasImage && 'margin-right: 0.75rem;'};
  /* transform: translate(0, 0); */
`;

const Inner = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  isMultiple,
  emoji,
  smallEmoji,
  ...props
}) => {
  const [animate, setAnimate] = useState(false);

  const handleMouseDown = () => {
    setAnimate(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimate(false);
    }, 100);
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Container
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      active={active}
      animate={animate}
      maxSelected={maxSelected}
      hasImage={!!ImageComponent}
      as="button"
      {...props}
    >
      {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
      {/* <Inner> */}
      <ButtonText hasImage={!!ImageComponent}> {children}</ButtonText>
      {/* </Inner> */}

      {!isMultiple && !ImageComponent && <Single />}
      {isMultiple && (
        <MultipleContainer active={active as boolean}>
          <MultipleArrow active={active as boolean} />
        </MultipleContainer>
      )}
    </Container>
  );
};

export default React.memo(QuizAnswerButton);

const ImageContainer = styled.div`
  margin-bottom: 1rem;

  width: 4rem;
  height: 4rem;
`;

const MultipleArrow = styled(Arrow)<{ active: boolean }>`
  transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0.7)')};
  transition: transform 0.3s ease-out;
  path {
    stroke: ${({ active }) => (active ? '#fff' : '#F7F7F7')};
  }
`;

const MultipleContainer = styled.section<{ active: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 6.25rem;

  background: ${({ active }) => (active ? '#2FBE8F' : '#F9F6F4')};
  border: 1.2px solid ${({ active }) => (active ? '#2FBE8F' : '#8E909A')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
