import React, { FC } from 'react';
import { ButtonProps, Button as MButton } from '@material-ui/core';
import styled from 'styled-components';

import { primary1, surface } from 'styles/colors';

const MButtonStyled = styled(MButton).attrs({
  style: {
    backgroundColor: primary1,
    boxShadow: '0 0.5rem 1rem rgba(0, 171, 85, 0.24)',
    borderRadius: '0.5rem',
    height: '3rem',
  },
})``;

const Title = styled.span`
  font-family: 'Poppins';
  color: ${surface};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.078125rem;
  text-transform: capitalize;
`;

const Button: FC<ButtonProps> = ({ children, ...props }) => (
  <MButtonStyled variant="contained" fullWidth {...props}>
    <Title>{children}</Title>
  </MButtonStyled>
);

export default Button;
