import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  desktop,
  mobile,
  smMobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import LCFooter from 'components/LCFooter';
import { usePageView, useScrollToTop } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { useLocation } from '@reach/router';
import { normalizeStates } from 'utils/localization';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

import LandingArrow from 'assets/icons/landing-arrow.svg';
import LandingArrowWhite from 'assets/icons/landing-arrow-white.svg';
import Triangle from 'assets/icons/triangle.svg';
import WhitePercent from 'assets/icons/white-percent.svg';

import ButtonMaleImg from 'assets/images/landing/landing-male.png';
import ButtonFemaleImg from 'assets/images/landing/landing-female.png';
import theme from 'utils/theme';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
  hideFooter?: boolean;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({
  question,
  onOptionSelect,
  hideFooter = false,
}) => {
  // const { isTablet } = useQuery();
  // const { countdownDiffInMS } = useFirstVisitDate();
  const location = useLocation();
  const questionParam = new URLSearchParams(location.search).get('q');
  const bfBannerExists = question?.bfBanner;
  // const quiz_flow_answers = useSelector(
  //   (state: AppState) => state.user?.quiz_flow_answers,
  // );
  // const isBrowser = typeof window !== 'undefined';
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;

  const geo = useSelector((state: AppState) => state.user?.geolocation);
  // const dispatch = useDispatch();
  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;

  useEffect(() => {
    const handleTouchStart = () => {};
    document.addEventListener('touchstart', handleTouchStart, {
      passive: true,
    });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  usePageView({
    city: encodeURIComponent(
      geo?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geo?.iso_country?.toLowerCase(),
    state: normalizeStates(geo?.iso_country || '', geo?.iso_state || ''),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.href, location.pathname]);
  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    setTimeout(() => {
      onOptionSelect({
        [question?.key]: item?.gender,
        label: item?.label,
      });
    }, 100);
  };

  const { isTablet, isMobile } = useQuery();

  const renderGenderButtons = (options: any[]) => (
    <>
      <GenderButtonContainer>
        {options?.map((option, index) => (
          <GenderButton
            key={index}
            activeBorder={option.activeBorderColor}
            activeBackground={option.activeBackgroundColor}
            onClick={() =>
              handleOptionsSelect({
                [question?.key]: option?.value,
                label: option?.label,
              })
            }
            textColor={option.textColor}
            bgColor={option.backgroundColor}
          >
            <ShadowWrapper>
              <GenderImageContainer backgroundColor={option.backgroundColor}>
                {option.value === 'male' ? (
                  <StyledGenderImage src={'landing/landing-male.png'} alt="" />
                ) : (
                  <StyledGenderImage
                    src={'landing/landing-female.png'}
                    alt=""
                  />
                )}
              </GenderImageContainer>
              <GenderTextContainer
                bgColor={option?.label === 'Male' ? '#2FBE8F' : '#C78CB8'}
              >
                <span>{option?.label}</span>
                {option &&
                option.label &&
                option.label.toLowerCase() === 'male' ? (
                  <StyledLandingArrowWhite />
                ) : (
                  <StyledLandingArrowWhite />
                )}
              </GenderTextContainer>
            </ShadowWrapper>
          </GenderButton>
        ))}
      </GenderButtonContainer>
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsWrapper>
        <ButtonsContainer
          length={[...primaryOptions, ...secondaryOptions].length}
        >
          {renderGenderButtons(primaryOptions)}
          {renderGenderBasic(secondaryOptions)}
        </ButtonsContainer>
        {question?.bannerButtonText && (
          <ButtonsBanner bgColor={question?.bannerButtonBgColor}>
            <WhitePercent />
            <LeftTriangle fillColor={question?.bannerButtonBgColor} />
            <RightTriangle fillColor={question?.bannerButtonBgColor} />
            <ButtonBannerText
              spanColor={question?.bannerButtonSpanColor}
              dangerouslySetInnerHTML={{
                __html: question?.bannerButtonText,
              }}
            />
          </ButtonsBanner>
        )}
      </ButtonsWrapper>
    );
  };

  useScrollToTop(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Container hideFooter={hideFooter}>
        {!isAddsFlow ? (
          <Header
            isMobileApp={false}
            ref={headerRef}
            banner={question?.bannerTexts}
            bgHex="#F9F6F4"
          />
        ) : (
          <Header
            isMobileApp={true}
            banner={question?.bannerTexts}
            bgHex="#F9F6F4"
          />
        )}
        <Wrapper isFullHeight={isAddsFlow}>
          <BackgroundImageContainer isAddsFlow={isAddsFlow} />
          <WrappedItem>
            <LandingContainer>
              <LandingTextContainer>
                <TitleContainer>
                  <Title
                    textAlign={'center'}
                    type="h1"
                    dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                  />
                </TitleContainer>
                <DescriptionContainer>
                  <Label
                    textAlign={'center'}
                    type="h2"
                    dangerouslySetInnerHTML={{
                      __html: question?.description || '',
                    }}
                    descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                  />
                </DescriptionContainer>
              </LandingTextContainer>
              <GenderContainer>
                <ButtonsTitleContainer>
                  <ButtonsLabel
                    dangerouslySetInnerHTML={{ __html: question?.label }}
                    textAlign={'center'}
                  />
                </ButtonsTitleContainer>
                {renderGenderOptions()}
              </GenderContainer>
            </LandingContainer>
          </WrappedItem>
        </Wrapper>
        <FooterContainer>
          {!isAddsFlow ? (
            <LCFooter
              disclaimerText={question?.disclaimerParagraphs || []}
              whiteFooter
            />
          ) : (
            <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
          )}
        </FooterContainer>
      </Container>
    </>
  );
};

export default Landing;

const Container = styled.div<{
  hideFooter?: boolean;
  backgroundImage?: string;
  backgroundImageMob?: string;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f6f4;
  overflow-x: hidden;
`;

const WrappedItem = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.main<{
  isFullHeight?: boolean;
  backgroundImage?: string;
}>`
  position: relative;
  height: 100%;
  ${({ isFullHeight }) =>
    isFullHeight
      ? 'min-height: calc(100dvh - 64px);'
      : 'min-height: calc(100dvh - 234px);'}
  ${({ isFullHeight }) => isFullHeight && '-webkit-fill-available;'}

  padding-bottom: 5.3rem;

  @media ${tablet} {
    padding-bottom: 8.81rem;
    ${({ isFullHeight }) => !isFullHeight && 'min-height: unset;'}
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.038rem;

  @media ${tablet} {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.042rem;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  color: #28293d;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.018rem;

  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.016rem;
  }
`;

const ButtonsLabel = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.014rem;
`;

const DescriptionContainer = styled.div``;

const TitleContainer = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
  }
`;

const GenderImageContainer = styled.div<{
  backgroundColor?: string;
  activeBackgroundColor?: string;
}>`
  max-width: 13.4375rem;
  height: 9.01294rem;
  pointer-events: none;
  position: relative;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};
  transition: background-color 100ms ease-in;
  border-radius: 1rem 1rem 0rem 0rem;
  width: 100%;

  @media ${tablet} {
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    max-width: 10.25rem;
    height: 6.875rem;
  }
`;

const GenderButton = styled.button<{
  variant?: string;
  activeBorder: keyof DefaultTheme['colors'];
  activeBackground: string;
  textColor?: string;
  bgColor?: string;
}>`
  color: ${({ theme }) => theme.colors.dark100} !important;
  display: flex;
  max-width: 13.4375rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  transition: box-shadow 100ms ease-in, transform 100ms ease-in,
    background-color 100ms ease-in;
  font-size: 1.33331rem;
  font-weight: 500;
  outline-offset: -2px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 1rem;
  min-height: 16.51494rem;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  min-width: 5.625rem;

  @media ${tablet} {
    display: flex;
    max-width: 10.25rem;
    min-height: 12.4rem;
    font-size: 0.99406rem;
    border-radius: 0.75rem;
  }

  @media ${smMobile} {
    min-height: 10.6rem;
  }

  @media ${sxMobile} {
    min-height: 10.3rem;
  }

  &:active {
    box-shadow: none;
    transform: scale(0.99);
    transition: transform 100ms ease-out;

    ${GenderImageContainer} {
      background-color: ${({ activeBackground }) => activeBackground};
      transition: background-color 100ms ease-out;
    }

    background: transparent;

    div {
      box-shadow: none;
    }
  }

  &:focus-visible {
    outline: none;
    border: none;
    background: transparent;
  }

  span {
    color: ${({ textColor }) => (textColor ? textColor : '#000')} !important;
  }
`;

const ShadowWrapper = styled.div`
  box-shadow: 0px 5.234px 13.086px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 100ms ease-in;
  border-radius: 1rem;
  width: 100%;

  ${GenderButton}:active & {
    box-shadow: none;
  }

  @media ${tablet} {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.8rem;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: #f3f3f8;

  border: 1px solid #8e909a;
  div p {
    text-align: center;
    color: #8e909a;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 1rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  text-align: center;
  @media ${tablet} {
  }
`;

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  width: 100%;
  gap: 1.5rem;
  padding: 3rem 0;
  z-index: 10;

  @media ${tablet} {
    padding: 1.5rem;
  }
`;

const GenderContainer = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 28.75rem;
  width: 100%;
`;

const GenderButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${tablet} {
    justify-content: center;
    gap: 0.75rem;
  }
`;

const LandingTextContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const FooterContainer = styled.div`
  margin-top: auto;
  z-index: 99;
`;

const BackgroundImageContainer = styled.div<{ isAddsFlow?: boolean }>`
  position: absolute;
  bottom: -4px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 72rem;

  img {
    width: 100%;
    object-fit: cover;

    path {
      fill: #c7ccdb;
    }

    @media ${mobile} {
      width: 32rem;
      ${({ isAddsFlow }) => isAddsFlow && 'height: 100%;'}
    }
  }

  @media ${tablet} {
    /* bottom: ${({ isAddsFlow }) => (isAddsFlow ? '0' : '0;')}; */
  }

  @media ${mobile} {
    bottom: ${({ isAddsFlow }) => (isAddsFlow ? '0' : '0')};
    right: 0;
    width: 100%;
    max-width: 26.1875rem;
    /* opacity: 0.6; */
  }
`;

const StyledGenderImage = styled(DynamicImage)`
  pointer-events: none;
  position: absolute;
  width: 100%;
  bottom: -1px;
`;

const GenderTextContainer = styled.div<{ bgColor: string }>`
  height: 3.2715rem;
  padding: 0.73606rem 0.98144rem;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  border-radius: 0rem 0rem 1rem 1rem;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: white !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @media ${tablet} {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.016rem;
    }
  }

  svg {
    width: 0.75rem;
    height: 1.5rem;
  }

  @media ${tablet} {
    height: 2.5rem;
    padding: 0.5625rem 0.75rem;
    border-radius: 0rem 0rem 0.75rem 0.75rem;
  }
`;

const StyledLandingArrow = styled(LandingArrow)<{ fill?: string }>`
  width: 0.99113rem;
  height: 1.54169rem;

  @media ${tablet} {
    width: unset;
    height: unset;
  }
`;

const StyledLandingArrowWhite = styled(LandingArrowWhite)`
  /* width: 0.75rem;
  height: 1.5rem; */
`;

const HeaderWrapper = styled.div<{ isSticky?: boolean }>`
  width: 100%;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: 0;
`;

const ButtonsBanner = styled.p<{ bgColor?: string }>`
  position: relative;
  display: flex;
  max-width: 38.8125rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#a4202f')};
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 0.875rem;
  gap: 0.5rem;
  height: 3.5rem;
  @media ${tablet} {
    max-width: 21.25rem;
    height: 3rem;
  }
`;

const LeftTriangle = styled(Triangle)<{ fillColor?: string }>`
  position: absolute;
  top: -0.375rem;
  left: 20%;

  path {
    fill: ${({ fillColor }) => (fillColor ? fillColor : '#A4202F')};
  }
`;

const RightTriangle = styled(Triangle)<{ fillColor?: string }>`
  position: absolute;
  top: -0.375rem;
  right: 20%;

  path {
    fill: ${({ fillColor }) => (fillColor ? fillColor : '#A4202F')};
  }
`;

const ButtonBannerText = styled.p<{ spanColor?: string }>`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;

  b {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  span {
    color: ${({ spanColor }) => spanColor ?? '#E6B241'};
    font-weight: 700;
  }

  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.024rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
