import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from 'assets/icons/arrow-left.svg';
import Text from 'components/Text';
import ButtonContainer from 'components/ButtonContainer';
import { tablet } from 'styles/breakpoints';

interface BackButtonProps {
  onClick: () => void;
}

const Container = styled(ButtonContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
  width: 1.5rem;
  height: 1.5rem;
  @media ${tablet} {
    width: 1rem;
    height: 1rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 6.25rem;
  background: #f9f9f9;
  justify-content: center;
  align-items: center;
  margin-right: 0.62rem;
  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
  }
`;

const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <Container onClick={onClick}>
    <IconContainer>
      <ArrowLeftIconStyled />
    </IconContainer>
  </Container>
);

export default React.memo(BackButton);
